/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-header {
  background-image: url("assets/header.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #31708E;
  width: 100%;
  height: calc(10vh);
  margin-bottom: 5px;
}

.cookie-assistant{
  --border-radius: 10px;
  max-width: 1000px;
  max-height: 800px;
  --width: 80vw;
  --height: 80vh;
  margin: 0 auto
}

.cookie-confirm{
  --border-radius: 10px;
  --height: 250px;
}

.gmx-modal{
  --width: 780px;
  --height: 810px;
  --border-radius: 4px;
  padding: 30px 30px 20px;
}

.spiegel-modal{
  --width: 658px;
  --height: 758.55px;
  --padding: 16px;
}

.bild-modal {
  --padding: 20px 30px;
  --border-radius: 6px;
  --width: 810px;
  --height: 735px;
}

.amazon-modal {
  --position: relative;
  --width: 100%;
}

.ebay-modal{
  --width: 100%;
  --height: 269px;
}

//FACEBOOK
.facebook-modal{
  --width: 680px;
  --height: 662px;
  --border-radius: 3px;
}
// YOUTUBE PAGE
.youtube-modal{
  --width: 700px;
  --height: 852px;
  --border-radius: 2px;
}

// GOOGLE PAGE
.google-modal{
  --width: 800px;
  --height: 850px;
  --border-radius: 1em;
}

.fullsize-adjust{
  --width: 100%;
  --height: 90vh;
  top: 10vh;
}



:root {
  //--ion-text-color: #687864;


  --ion-color-primary: #31708E;
  --ion-color-primary-rgb: 49,112,142;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #2b637d;
  --ion-color-primary-tint: #467e99;

  --ion-color-secondary: #5085A5;
  --ion-color-secondary-rgb: 80,133,165;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #467591;
  --ion-color-secondary-tint: #6291ae;

  --ion-color-tertiary: #8FC1E3;
  --ion-color-tertiary-rgb: 143,193,227;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #7eaac8;
  --ion-color-tertiary-tint: #9ac7e6;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f7f9fb;
  --ion-color-light-rgb: 247,249,251;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d9dbdd;
  --ion-color-light-tint: #f8fafb;

}
